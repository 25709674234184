<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="time"
        type="date"
        placeholder="选择日期"
        size="mini"
      />
      <el-button
        size="mini"
        v-waves
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="timeSerach"
      >
        {{ "搜索" }}
      </el-button>
    </div>
    <el-table
      :data="userList"
      style="width:100%;margin-top:30px;"
      highlight-current-row
      border
    >
      <el-table-column
        align="center"
        label="生成照片"
      >
        <template #default="{row}">
          <img
            :src="row.download"
            width="70"
            height="70"
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="时间"
      >
        <template #default="{row}">
          {{ row.create_time }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="描述"
      >
        <template #default="{row}">
          {{ row.desc }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      v-model:page="listQuery.page"
      v-model:limit="listQuery.limit"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 50, 100]"
      layout="total, sizes, prev, pager, next, jumper"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, onBeforeMount } from 'vue'
import { aifacerephotocord } from '@/apis/aiface'
export default defineComponent({
  setup() {
    const state = reactive({
      time: '',
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id'
      },
      total: 0,
      userList: [],
      getAllUser: async(pageNum?: number, pageSize?: number) => {
        if (pageNum) {
          state.listQuery.page = pageNum
        }
        if (pageSize) {
          state.listQuery.limit = pageSize
        }
        await aifacerephotocord({ pageNum: state.listQuery.page, pageSize: state.listQuery.limit, time: state.time }).then((res: any) => {
          state.userList = res?.data
          state.total = res.totalpages
        })
      },
      timeSerach() {
        state.getAllUser()
      },
      handleCurrentChange(pageNum?: any) {
        state.getAllUser(pageNum)
      },
      handleSizeChange(val: any) {
        state.getAllUser(0, val)
      }
    })
    onBeforeMount(() => {
      state.getAllUser(state.listQuery.page, state.listQuery.limit)
    })
    return {
      ...toRefs(state)
    }
  }
})
</script>
