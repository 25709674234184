<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        size="small"
        placeholder="请输入标题"
        style="width: 200px;margin-right:10px"
        class="filter-item"
      />
      <el-button
        size="mini"
        type="primary"
        @click="handleCreateRole"
      >
        {{ "添加智能锁" }}
      </el-button>
    </div>
    <el-table
      :data="userList"
      style="width:100%;margin-top:30px;"
      highlight-current-row
      border
    >
      <el-table-column
        align="center"
        label="智能锁名称"
      >
        <template #default="{row}">
          {{ row.deptname }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="编号"
      >
        <template #default="{row}">
          {{ row.leader }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="在线状态"
      >
        <template #default="{row}">
          {{ row.parentid }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="启用时间"
      >
        <template #default="{row}">
          {{ row.phone }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="状态"
      >
        <template #default="{row}">
          {{ row.status === 0 ? "禁用" : "启用" }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
      >
        <template #default="scope">
          <el-button
            type="primary"
            size="mini"
            @click="handleUpdate(scope)"
          >
            {{ scope.row.status === 1 ? "禁用":"启用" }}
          </el-button>
          <el-button
            type="danger"
            size="mini"
          >
            {{ "删除" }}
          </el-button>
          <el-button
            type="success"
            size="mini"
            @click="handleCreateRole"
          >
            {{ "配置" }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      v-model="dialogVisible"
      width="40rem"
      :title="dialogType === 'edit' ? '修改账户' : '添加账户'"
    >
      <el-form
        :model="addDept"
        label-width="40px"
        label-position="left"
      >
        <el-form-item label="设备编号">
          <el-input
            size="mini"
            v-model="addDept.deptparent"
            placeholder="部门id"
          />
        </el-form-item>
        <el-form-item label="设备名称">
          <el-input
            size="mini"
            v-model="addDept.deptname"
            placeholder="账户"
          />
        </el-form-item>
        <el-form-item label="启用状态">
          <el-switch
            v-model="value2"
            class="mt-2"
            style="margin-left: 24px"
            inline-prompt
            :active-icon="Check"
            :inactive-icon="Close"
            active-text="启用"
            inactive-text="禁用"
          />
        </el-form-item>
        <el-form-item label="使用位置">
          <el-input
            size="mini"
            v-model="addDept.deptemail"
            placeholder="使用位置"
          />
        </el-form-item>
        <el-form-item label="启用密码">
          <el-input
            size="mini"
            v-model="addDept.deptaddress"
            placeholder="密码"
          />
        </el-form-item>
      </el-form>
      <div style="text-align:center;">
        <el-button
          size="mini"
          type="danger"
          @click="dialogVisible = false"
        >
          {{ "返回" }}
        </el-button>
        <el-button
          size="mini"
          type="primary"
        >
          {{ "确认" }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, onBeforeMount, getCurrentInstance, ref } from 'vue'
import { allDept, updateStatuDept, addDept } from '@/apis/user'

interface RoleData {
    id: number
    parentid: string|number
    deptname: string
    leader: string
    phone: string|number
    email: string|number
    status: any
    remark: string
    address: string
    subsidiary?: any[]
     hasChildren?: boolean
}
interface Dept {
    deptparent: number
    deptname: string|number
    deptphone: string|number
    deptemail: string
    deptstatus: string|number
    deptaddress: string|number
    deptremark: any
    deptleader: string
}
const defaultRole: Dept = {
  deptparent: 0,
  deptname: '',
  deptphone: '',
  deptemail: '',
  deptstatus: '',
  deptaddress: '',
  deptremark: '',
  deptleader: ''
}
export default defineComponent({
  setup() {
    const value2 = ref(true)
    // const frontUpload = ref('')
    const { ctx } = getCurrentInstance() as any
    const state = reactive({
      dialogVisible: false,
      dialogType: 'new',
      addDept: Object.assign({}, defaultRole),
      userList: [] as RoleData[],
      handleUpdate: (scope: any) => {
        const { row } = scope
        console.log(row)
        updateStatuDept({ enok: row.status === 0 ? 1 : 0, did: row.id }).then((res: any) => {
          if (res.code === 0) {
            ctx.$message({
              type: 'success',
              message: '修改成功'
            })
          } else {
            ctx.$message({
              type: 'success',
              message: '修改成功'
            })
          }
        })
      },
      handleCreateRole: () => {
        state.dialogType = 'new'
        state.dialogVisible = true
        console.log(state.dialogVisible)
      }
    })
    const getAllUser = async() => {
      await allDept().then((res: any) => {
        if (res.code === 0) {
          state.userList = res?.data
        } else {
          ctx.$message({
            type: 'danger',
            message: '暂无数据'
          })
        }
      })
    }
    const submitDept = async() => {
      await addDept({ ...state.addDept }).then((res: any) => {
        console.log(res)
      })
    }
    const deptDelete = async(scope: any) => {
      console.log(scope.row.id)
    }
    onBeforeMount(() => {
    //   getServiceRoutes()
      getAllUser()
    })
    return {
      ...toRefs(state),
      submitDept,
      deptDelete,
      value2
    }
  }
})
</script>
