
import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'
import { useStore } from '@/store'

interface Role {
    ustoken: string
    username: string|number
    password: string
    realname: string
    userdept: string|number
    userrole: string|number
    faceimg: any
}
const defaultRole: Role = {
  ustoken: useStore().state.user.token,
  username: '',
  password: '',
  realname: '我的',
  userdept: '',
  userrole: '',
  faceimg: ''
}

export default defineComponent({
  setup() {
    const state = reactive({
      rolesList: [] as any,
      dialogVisible: false,
      dialogType: 'new',
      checkStrictly: false,
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      role: Object.assign({}, defaultRole)
    })
    onBeforeMount(() => {
    //   getServiceRoutes()
    })
    return {
      ...toRefs(state)
    }
  }
})
