
<template>
  <div class="app-container">
    <el-button
      size="mini"
      type="primary"
      @click="handleCreateRole"
    >
      {{ "添加人脸" }}
    </el-button>

    <el-table
      :data="rolesList"
      style="width:100%;margin-top:30px;"
      border
    >
      <el-table-column
        align="center"
        label="人脸照片"
      >
        <template #default="{row}">
          {{ row.name }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="姓名"
      >
        <template #default="{row}">
          {{ row.name }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="手机号"
      >
        <template #default="{row}">
          {{ row.eid }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="授权设备"
      >
        <template #default="{row}">
          {{ row.eid }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="所属企业"
      >
        <template #default="{row}">
          {{ row.eid }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="Operations"
      >
        <template #default="scope">
          <el-button
            type="danger"
            size="mini"
            @click="handleUpdate(scope)"
          >
            {{ scope.row.eid === 0 ? "禁用":"启用" }}
          </el-button>
          <el-button
            type="danger"
            size="mini"
            @click="handleauthorize(scope)"
          >
            {{ "授权设备" }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      v-model="dialogVisible"
      width="22rem"
      :title="dialogType === 'new' ? '添加人员' : '授权设备'"
    >
      <el-form
        v-if="dialogType=== 'new'"
        :model="role"
        label-width="60px"
        label-position="left"
      >
        <el-form-item label="姓名">
          <el-input
            size="mini"
            v-model="role.realname"
            placeholder="姓名"
          />
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            size="mini"
            v-model="role.username"
            placeholder="手机号"
          />
        </el-form-item>
        <el-form-item label="人脸状态">
          <el-switch
            v-model="value2"
            class="mt-2"
            style="margin-left: 24px"
            inline-prompt
            :active-icon="Check"
            :inactive-icon="Close"
            active-text="启用"
            inactive-text="禁用"
          />
        </el-form-item>
        <el-form-item label="人脸照片">
          <el-upload
            class="upload-demo"
            action="https://ai.faceswap.bainiankeji.com/manageusers/"
            :file-list="fileList"
            name="faceimg"
            :data="{...role}"
            :on-success="handleSuccess"
            :on-progress="handProgress"
            :on-change="imgSaveToUrl"
            :show-file-list="true"
          >
            <el-button type="primary">
              Click to upload
            </el-button>
            <template #tip>
              <div class="el-upload__tip">
                jpg/png files with a size less than 500kb
              </div>
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-form
        v-if="dialogType=== 'authorize'"
        :model="role"
        label-width="60px"
        label-position="left"
      >
        <el-form-item label="授权设备">
          <el-select
            v-model="value1"
            multiple
            placeholder="Select"
            style="width: 240px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div style="text-align:center;">
        <el-button
          size="mini"
          type="danger"
          @click="dialogVisible = false"
        >
          {{ "返回" }}
        </el-button>
        <el-button
          v-if="dialogType=== 'new'"
          size="mini"
          type="primary"
          @click="addManage"
        >
          {{ "返回" }}
        </el-button>
        <el-button
          v-if="dialogType=== 'authorize'"
          size="mini"
          type="primary"
          @click="addManage"
        >
          {{ "确认" }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, reactive, toRefs, ref } from 'vue'
import { useRouter } from 'vue-router'
import { allRoles, addManage } from '@/apis/user'
import { ElMessage } from 'element-plus'
interface Role {
    username: string|number
    password: string
    realname: string
    userdept: string|number
    userrole: string|number
    faceimg: any
}
const defaultRole: Role = {
  username: '',
  password: '',
  realname: '我的',
  userdept: '',
  userrole: '',
  faceimg: ''
}

export default defineComponent({
  setup() {
    const form = document.getElementById('pic')
    const imageFrontUrl = ref('')
    const router = useRouter()
    const value1 = ref([])
    const value2 = ref(true)
    const state = reactive({
      rolesList: [] as any,
      dialogVisible: false,
      dialogType: 'new',
      checkStrictly: false,
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      options: [
        {
          value: 'Option1',
          label: '设备1'
        },
        {
          value: 'Option2',
          label: '设备2'
        },
        {
          value: 'Option3',
          label: '设备3'
        }
      ],
      role: Object.assign({}, defaultRole),
      handleCreateRole: () => {
        state.dialogType = 'new'
        state.dialogVisible = true
      },
      addManage: async() => {
        console.log(form)
        await addManage(state.role).then((res: any) => {
          console.log(res)
        })
      },
      handProgress: async(event: any) => {
        console.log(event)
      },
      getAllUser: async() => {
        await allRoles().then((res) => {
          state.rolesList = res?.data
        })
      },
      handleauthorize: () => {
        state.dialogType = 'handleauthorize'
        state.dialogVisible = true
      },
      checkUser: (scope: any) => {
        console.log(scope)
        router.push({
          path: 'user'
        })
      },
      changeState: (scope: any) => {
        console.log(scope)
      },
      handleSuccess: () => {
        state.dialogVisible = false
        state.role = {
          username: '',
          password: '',
          realname: '',
          userdept: '',
          userrole: '',
          faceimg: ''
        }
      }
    })
    const logoPicRemove = () => { // 清空内容
    //   frontUpload.value.clearFiles()
      imageFrontUrl.value = ''
    }
    const imgSaveToUrl = (file: any) => {
      console.log(file)
      imageFrontUrl.value = URL.createObjectURL(file.raw)
      const fileSize = file.size
      const fileType = file.raw.type
      if (!fileSize) {
      // 是否为空
        ElMessage({
          type: 'error',
          showClose: true,
          message: '无效的文件，请重新选择！'
        })
        return
      }
      if (fileSize / 1024 / 1024 > 10) {
      // 图片大小
        ElMessage({
          type: 'error',
          showClose: true,
          message: '请上传小于10M的图片！'
        })
        logoPicRemove()
        return
      }
      if (fileType.indexOf('image') === -1) {
      // 如果不是图片格式
        ElMessage({
          type: 'error',
          showClose: true,
          message: '不是有效的图片文件，或格式不支持，请重新选择!'
        })
        logoPicRemove()
      }
    }
    // const getServiceRoutes = () => {
    //   getRoutes().then((res) => {
    //     state.serviceRoutes = res?.data.routes as any as RouteRecordRaw[]
    //     state.reshapedRoutes = getReshapeRoutes(res?.data.routes as any as RouteRecordRaw[])
    //   })
    // }
    onBeforeMount(() => {
    //   getServiceRoutes()
      state.getAllUser()
    })
    return {
      ...toRefs(state),
      imgSaveToUrl,
      value1,
      value2
    }
  }
})
</script>

<style lang="scss" scoped>
.app-container {
  .roles-table {
    margin-top: 30px;
  }
 .editor-slide-upload {
  .el-upload--picture-card {
    width: 100%;
  }
}
  .uploadImag{
      width: 200px;
      height: 100px;
      background-color: green;
  }
}
</style>
