<!--
 * @Description: 角色切换和菜单配置（后端控制权限使用）
 * @Author: ZY
 * @Date: 2021-01-08 19:32:16
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-13 16:49:21
-->
<template>
  <div class="app-container">
    <el-table
      :data="rolesList"
      style="width:100%;margin-top:30px;"
      border
    >
      <el-table-column
        align="center"
        label="设备名称"
      >
        <template #default="{row}">
          {{ row.name }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="设备号"
      >
        <template #default="{row}">
          {{ row.eid }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="时间"
      >
        <template #default="{row}">
          {{ row.eid }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="设备事件"
      >
        <template #default="{row}">
          {{ row.eid }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作人"
      >
        <template #default="{row}">
          {{ row.eid }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="Operations"
      >
        <template #default="scope">
          <el-button
            type="danger"
            size="mini"
            @click="handleUpdate(scope)"
          >
            {{ "删除" }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'
import { useStore } from '@/store'

interface Role {
    ustoken: string
    username: string|number
    password: string
    realname: string
    userdept: string|number
    userrole: string|number
    faceimg: any
}
const defaultRole: Role = {
  ustoken: useStore().state.user.token,
  username: '',
  password: '',
  realname: '我的',
  userdept: '',
  userrole: '',
  faceimg: ''
}

export default defineComponent({
  setup() {
    const state = reactive({
      rolesList: [] as any,
      dialogVisible: false,
      dialogType: 'new',
      checkStrictly: false,
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      role: Object.assign({}, defaultRole)
    })
    onBeforeMount(() => {
    //   getServiceRoutes()
    })
    return {
      ...toRefs(state)
    }
  }
})
</script>

<style lang="scss" scoped>
.app-container {
  .roles-table {
    margin-top: 30px;
  }
 .editor-slide-upload {
  .el-upload--picture-card {
    width: 100%;
  }
}
  .uploadImag{
      width: 200px;
      height: 100px;
      background-color: green;
  }
}
</style>
