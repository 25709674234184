
import { defineComponent, reactive, toRefs, onBeforeMount, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { CHptotoRequest, deleteChPhoto, UpdateChPhoto, operaptotoRequest, deleteoperaPhoto, UpdateoperaPhoto, cartoonptotoRequest, deletecartoonPhoto, UpdatecartoonPhoto, sketchptotoRequest, deletesketchPhoto, UpdatesketchPhoto } from '@/apis/aiface'
import { useStore } from '@/store'

export default defineComponent({
  setup() {
    const imageFrontUrl = ref('')
    const token = useStore().state.user.token
    const state = reactive({
      uploadUrl: '',
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id'
      },
      activeName: 'first',
      options: [
        {
          value: '1',
          label: '超级管理员'
        },
        {
          value: '2',
          label: '管理员'
        },
        {
          value: '3',
          label: '普通用户'
        }
      ],
      tableDataProps: {
        value: 'id',
        label: 'deptname',
        children: 'children',
        checkStrictly: true,
        emitPath: false,
        expandTrigger: 'click'
      },
      dialogVisible: false,
      dialogType: 'new',
      userList: [],
      deptList: [],
      handleUpdate: async(scope: any) => {
        const enable = scope.row.enable === 0 ? 1 : 0
        switch (state.activeName) {
          case 'first' : await UpdateChPhoto({ enable: enable, imgid: scope.row.id }).then((res: any) => {
            console.log(res)
            if (res.code === 0) {
              ElMessage({
                type: 'success',
                message: res?.msg
              })
              state.getAllCHptoto()
            } else {
              ElMessage({
                type: 'error',
                message: res?.msg
              })
            }
          })
            break
          case 'second' : await UpdateoperaPhoto({ enable: enable, imgid: scope.row.id }).then((res: any) => {
            console.log(res)
            if (res.code === 0) {
              ElMessage({
                type: 'success',
                message: res?.msg
              })
              state.getAlloperaptoto()
            } else {
              ElMessage({
                type: 'error',
                message: res?.msg
              })
            }
          })
            break
          case 'third' : await UpdatesketchPhoto({ enable: enable, imgid: scope.row.id }).then((res: any) => {
            console.log(res)
            if (res.code === 0) {
              ElMessage({
                type: 'success',
                message: res?.msg
              })
              state.getAllsketchptoto()
            } else {
              ElMessage({
                type: 'error',
                message: res?.msg
              })
            }
          })
            break
          case 'fourth' : await UpdatecartoonPhoto({ enable: enable, imgid: scope.row.id }).then((res: any) => {
            console.log(res)
            if (res.code === 0) {
              ElMessage({
                type: 'success',
                message: res?.msg
              })
              state.getAllcartoonptoto()
            } else {
              ElMessage({
                type: 'error',
                message: res?.msg
              })
            }
          })
            break
        }
      },
      handleClick: async() => {
        switch (state.activeName) {
          case 'first' : state.getAllCHptoto()
            break
          case 'second' : state.getAlloperaptoto()
            break
          case 'third' : state.getAllsketchptoto()
            break
          case 'fourth': state.getAllcartoonptoto()
            break
        }
      },
      getAlloperaptoto: async(pageNum?: number, pageSize?: number) => {
        if (pageNum) {
          state.listQuery.page = pageNum
        }
        if (pageSize) {
          state.listQuery.limit = pageSize
        }
        await operaptotoRequest({ pageNum: state.listQuery.page, pageSize: state.listQuery.limit }).then((res: any) => {
          state.userList = res?.data
          state.total = res.totalpages
        })
        state.uploadUrl = 'https://ai.faceswap.bainiankeji.com/theatretyle/'
      },
      getAllsketchptoto: async(pageNum?: number, pageSize?: number) => {
        if (pageNum) {
          state.listQuery.page = pageNum
        }
        if (pageSize) {
          state.listQuery.limit = pageSize
        }
        await sketchptotoRequest({ pageNum: state.listQuery.page, pageSize: state.listQuery.limit }).then((res: any) => {
          state.userList = res?.data
          state.total = res.totalpages
        })
        state.uploadUrl = 'https://ai.faceswap.bainiankeji.com/sketchstyle/'
      },
      getAllcartoonptoto: async(pageNum?: number, pageSize?: number) => {
        if (pageNum) {
          state.listQuery.page = pageNum
        }
        if (pageSize) {
          state.listQuery.limit = pageSize
        }
        await cartoonptotoRequest({ pageNum: state.listQuery.page, pageSize: state.listQuery.limit }).then((res: any) => {
          state.userList = res?.data
          state.total = res.totalpages
        })
        state.uploadUrl = 'https://ai.faceswap.bainiankeji.com/cartoonstyle/'
      },
      getAllCHptoto: async(pageNum?: number, pageSize?: number) => {
        if (pageNum) {
          state.listQuery.page = pageNum
        }
        if (pageSize) {
          state.listQuery.limit = pageSize
        }
        await CHptotoRequest({ pageNum: state.listQuery.page, pageSize: state.listQuery.limit }).then((res: any) => {
          state.userList = res?.data
          state.total = res.totalpages
        })
        state.uploadUrl = 'https://ai.faceswap.bainiankeji.com/chinastyle/'
      },
      deleteManageBtn: async(scope: any) => {
        const id = scope.row.id
        switch (state.activeName) {
          case 'first' : await deleteChPhoto({ imgid: id }).then((res: any) => {
            console.log(res)
            if (res.code === 0) {
              ElMessage({
                type: 'success',
                message: res?.msg
              })
              state.getAllCHptoto()
            } else {
              ElMessage({
                type: 'error',
                message: res?.msg
              })
            }
          })
            break
          case 'second' : await deleteoperaPhoto({ imgid: id }).then((res: any) => {
            console.log(res)
            if (res.code === 0) {
              ElMessage({
                type: 'success',
                message: res?.msg
              })
              state.getAlloperaptoto()
            } else {
              ElMessage({
                type: 'error',
                message: res?.msg
              })
            }
          })
            break
          case 'third' : await deletesketchPhoto({ imgid: id }).then((res: any) => {
            console.log(res)
            if (res.code === 0) {
              ElMessage({
                type: 'success',
                message: res?.msg
              })
              state.getAllsketchptoto()
            } else {
              ElMessage({
                type: 'error',
                message: res?.msg
              })
            }
          })
            break
          case 'fourth' : await deletecartoonPhoto({ imgid: id }).then((res: any) => {
            console.log(res)
            if (res.code === 0) {
              ElMessage({
                type: 'success',
                message: res?.msg
              })
              state.getAllcartoonptoto()
            } else {
              ElMessage({
                type: 'error',
                message: res?.msg
              })
            }
          })
            break
        }
      },
      handleCreateRole: () => {
        state.dialogType = 'new'
        state.dialogVisible = true
      }
    })
    const logoPicRemove = () => { // 清空内容
    //   frontUpload.value.clearFiles()
      imageFrontUrl.value = ''
    }
    const imageUpload = async(file: any) => {
      console.log(file)
    }
    const imgSaveToUrl = (file: any) => {
      imageFrontUrl.value = URL.createObjectURL(file.raw)
      const fileSize = file.size
      const fileType = file.raw.type
      if (!fileSize) {
      // 是否为空
        ElMessage({
          type: 'error',
          showClose: true,
          message: '无效的文件，请重新选择！'
        })
        return
      }
      if (fileSize / 1024 / 1024 > 10) {
      // 图片大小
        ElMessage({
          type: 'error',
          showClose: true,
          message: '请上传小于10M的图片！'
        })
        logoPicRemove()
        return
      }
      if (fileType.indexOf('image') === -1) {
      // 如果不是图片格式
        ElMessage({
          type: 'error',
          showClose: true,
          message: '不是有效的图片文件，或格式不支持，请重新选择!'
        })
        logoPicRemove()
        return
      }
      imageUpload(file)
    }
    const handleSuccess = (res: any) => {
      if (res.code === 0) {
        setTimeout(() => {
          ElMessage({
            type: 'success',
            message: '添加成功'
          })
          switch (state.activeName) {
            case 'first' : state.getAllCHptoto()
              break
            case 'second' : state.getAlloperaptoto()
              break
            case 'third' : state.getAllsketchptoto()
              break
            case 'fourth': state.getAllcartoonptoto()
              break
          }
          state.dialogVisible = false
        }, 1000)
      } else {
        setTimeout(() => {
          ElMessage({
            type: 'success',
            message: res?.msg
          })
          state.dialogVisible = false
        }, 1000)
      }
    }

    onBeforeMount(() => {
    //   getServiceRoutes()
      state.getAllCHptoto()
    })
    return {
      token,
      ...toRefs(state),
      imgSaveToUrl,
      handleSuccess
    }
  }
})
