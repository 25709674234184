
import { defineComponent, reactive, toRefs, onBeforeMount } from 'vue'
import { aifacerephotocord } from '@/apis/aiface'
export default defineComponent({
  setup() {
    const state = reactive({
      time: '',
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id'
      },
      total: 0,
      userList: [],
      getAllUser: async(pageNum?: number, pageSize?: number) => {
        if (pageNum) {
          state.listQuery.page = pageNum
        }
        if (pageSize) {
          state.listQuery.limit = pageSize
        }
        await aifacerephotocord({ pageNum: state.listQuery.page, pageSize: state.listQuery.limit, time: state.time }).then((res: any) => {
          state.userList = res?.data
          state.total = res.totalpages
        })
      },
      timeSerach() {
        state.getAllUser()
      },
      handleCurrentChange(pageNum?: any) {
        state.getAllUser(pageNum)
      },
      handleSizeChange(val: any) {
        state.getAllUser(0, val)
      }
    })
    onBeforeMount(() => {
      state.getAllUser(state.listQuery.page, state.listQuery.limit)
    })
    return {
      ...toRefs(state)
    }
  }
})
