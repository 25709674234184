import { RootObject } from '@/model/rootObject'
import https from '@/utils/https'
import { RequestParams, ContentType, Method } from 'axios-mapper'
import { Allphoto } from '@/model/aifaceModel'
export const CHptotoRequest = (data: any) => {
  return https(true).request<RootObject<Allphoto>>('chinastyle/', Method.GET, data, ContentType.form)
}
export const AddChphoto = (data: RequestParams) => {
  return https(true).request<RootObject<Allphoto>>('chinastyle/', Method.POST, data, ContentType.form)
}
export const deleteChPhoto = (data: RequestParams) => {
  return https(true).request<RootObject<any>>('chinastyle/', Method.DELETE, data, ContentType.form)
}
export const UpdateChPhoto = (data: RequestParams) => {
  return https(true).request<RootObject<any>>('chinastyle/', Method.PUT, data, ContentType.form)
}
export const operaptotoRequest = (data: any) => {
  return https(true).request<RootObject<Allphoto>>('theatretyle/', Method.GET, data, ContentType.form)
}
export const Addoperaphoto = (data: RequestParams) => {
  return https(true).request<RootObject<Allphoto>>('theatretyle/', Method.POST, data, ContentType.form)
}
export const deleteoperaPhoto = (data: RequestParams) => {
  return https(true).request<RootObject<any>>('theatretyle/', Method.DELETE, data, ContentType.form)
}
export const UpdateoperaPhoto = (data: RequestParams) => {
  return https(true).request<RootObject<any>>('theatretyle/', Method.PUT, data, ContentType.form)
}
export const cartoonptotoRequest = (data: any) => {
  return https(true).request<RootObject<Allphoto>>('cartoonstyle/', Method.GET, data, ContentType.form)
}
export const Addcartoonphoto = (data: RequestParams) => {
  return https(true).request<RootObject<Allphoto>>('cartoonstyle/', Method.POST, data, ContentType.form)
}
export const deletecartoonPhoto = (data: RequestParams) => {
  return https(true).request<RootObject<any>>('cartoonstyle/', Method.DELETE, data, ContentType.form)
}
export const UpdatecartoonPhoto = (data: RequestParams) => {
  return https(true).request<RootObject<any>>('cartoonstyle/', Method.PUT, data, ContentType.form)
}

export const sketchptotoRequest = (data: any) => {
  return https(true).request<RootObject<Allphoto>>('sketchstyle/', Method.GET, data, ContentType.form)
}
export const Addsketchphoto = (data: RequestParams) => {
  return https(true).request<RootObject<Allphoto>>('sketchstyle/', Method.POST, data, ContentType.form)
}
export const deletesketchPhoto = (data: RequestParams) => {
  return https(true).request<RootObject<any>>('sketchstyle/', Method.DELETE, data, ContentType.form)
}
export const UpdatesketchPhoto = (data: RequestParams) => {
  return https(true).request<RootObject<any>>('sketchstyle/', Method.PUT, data, ContentType.form)
}
export const WxusersManage = (data: RequestParams) => {
  return https(true).request<RootObject<any>>('wxusers/', Method.GET, data, ContentType.form)
}
export const WxusersNumberChange = (data: RequestParams) => {
  return https(true).request<RootObject<any>>('wxusers/', Method.PUT, data, ContentType.form)
}
export const Wxpayrecord = (data: RequestParams) => {
  return https(true).request<RootObject<any>>('wxpaylogs/', Method.GET, data, ContentType.form)
}
export const aifacerecord = (data: RequestParams) => {
  return https(true).request<RootObject<any>>('sketchstyle/', Method.PUT, data, ContentType.form)
}
export const aifacerephotocord = (data: RequestParams) => {
  return https(true).request<RootObject<any>>('faceswaplogs/', Method.GET, data, ContentType.form)
}
