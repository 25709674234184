
import { defineComponent, reactive, toRefs, onBeforeMount, getCurrentInstance, ref } from 'vue'
import { allDept, updateStatuDept, addDept } from '@/apis/user'

interface RoleData {
    id: number
    parentid: string|number
    deptname: string
    leader: string
    phone: string|number
    email: string|number
    status: any
    remark: string
    address: string
    subsidiary?: any[]
     hasChildren?: boolean
}
interface Dept {
    deptparent: number
    deptname: string|number
    deptphone: string|number
    deptemail: string
    deptstatus: string|number
    deptaddress: string|number
    deptremark: any
    deptleader: string
}
const defaultRole: Dept = {
  deptparent: 0,
  deptname: '',
  deptphone: '',
  deptemail: '',
  deptstatus: '',
  deptaddress: '',
  deptremark: '',
  deptleader: ''
}
export default defineComponent({
  setup() {
    const value2 = ref(true)
    // const frontUpload = ref('')
    const { ctx } = getCurrentInstance() as any
    const state = reactive({
      dialogVisible: false,
      dialogType: 'new',
      addDept: Object.assign({}, defaultRole),
      userList: [] as RoleData[],
      handleUpdate: (scope: any) => {
        const { row } = scope
        console.log(row)
        updateStatuDept({ enok: row.status === 0 ? 1 : 0, did: row.id }).then((res: any) => {
          if (res.code === 0) {
            ctx.$message({
              type: 'success',
              message: '修改成功'
            })
          } else {
            ctx.$message({
              type: 'success',
              message: '修改成功'
            })
          }
        })
      },
      handleCreateRole: () => {
        state.dialogType = 'new'
        state.dialogVisible = true
        console.log(state.dialogVisible)
      }
    })
    const getAllUser = async() => {
      await allDept().then((res: any) => {
        if (res.code === 0) {
          state.userList = res?.data
        } else {
          ctx.$message({
            type: 'danger',
            message: '暂无数据'
          })
        }
      })
    }
    const submitDept = async() => {
      await addDept({ ...state.addDept }).then((res: any) => {
        console.log(res)
      })
    }
    const deptDelete = async(scope: any) => {
      console.log(scope.row.id)
    }
    onBeforeMount(() => {
    //   getServiceRoutes()
      getAllUser()
    })
    return {
      ...toRefs(state),
      submitDept,
      deptDelete,
      value2
    }
  }
})
