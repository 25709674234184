<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="wechatname"
        placeholder="请输入微信名称"
        size="mini"
        style="width: 200px;margin-right:10px"
        class="filter-item"
      />
      <el-button
        size="mini"
        v-waves
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="timeSerach"
      >
        {{ "搜索" }}
      </el-button>
    </div>
    <el-table
      :data="userList"
      style="width:100%;margin-top:30px;"
      highlight-current-row
      border
    >
      <el-table-column
        align="center"
        label="用户名"
      >
        <template #default="{row}">
          {{ row.username }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="图片次数"
      >
        <template #default="{row}">
          {{ row.freeimgnum }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="视频次数"
      >
        <template #default="{row}">
          {{ row.freevidnum }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="状态"
      >
        <template #default="{row}">
          {{ row.enable === 0 ? '启用':'禁用' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
      >
        <template #default="scope">
          <el-button
            type="primary"
            size="mini"
            @click="handleEditRole(scope)"
          >
            {{ "编辑" }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      v-model:page="listQuery.page"
      v-model:limit="listQuery.limit"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 50, 100]"
      layout="total, sizes, prev, pager, next, jumper"
    />
    <el-dialog
      v-model="dialogVisible"
      width="22rem"
      title="编辑用户"
    >
      <el-form
        :model="user"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="图片次数">
          <el-input
            size="mini"
            v-model.number="user.ifreeint"
            placeholder="请输入图片次数"
          />
        </el-form-item>
        <el-form-item label="视频次数">
          <el-input
            size="mini"
            v-model.number="user.vfreeint"
            placeholder="请输入视频次数"
          />
        </el-form-item>
      </el-form>
      <div style="text-align:center;">
        <el-button
          size="mini"
          type="danger"
          @click="dialogVisible = false"
        >
          {{ "返回" }}
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="handleUpdate"
        >
          {{ "确认" }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, onBeforeMount } from 'vue'
import { WxusersManage, WxusersNumberChange } from '@/apis/aiface'
import { ElMessage } from 'element-plus'
interface User {
     uid: number
     ifreeint: number
     vfreeint: number
}
const defaultUser: User = {
  uid: 0,
  ifreeint: 0,
  vfreeint: 0
}
export default defineComponent({
  setup() {
    const state = reactive({
      dialogVisible: false,
      wechatname: '',
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id'
      },
      user: Object.assign({}, defaultUser),
      total: 0,
      userList: [],
      getAllUser: async(pageNum?: number, pageSize?: number) => {
        if (pageNum) {
          state.listQuery.page = pageNum
        }
        if (pageSize) {
          state.listQuery.limit = pageSize
        }
        await WxusersManage({ pageNum: state.listQuery.page, pageSize: state.listQuery.limit, wechatname: state.wechatname }).then((res: any) => {
          state.userList = res?.data
          state.total = res.totalpages
        })
      },
      timeSerach() {
        state.getAllUser()
      },
      handleCurrentChange(pageNum?: any) {
        state.getAllUser(pageNum)
      },
      handleSizeChange(val: any) {
        state.getAllUser(0, val)
      },
      handleEditRole: (scope: any) => {
        console.log(scope)
        state.dialogVisible = true
        state.user = {
          uid: scope.row.uid,
          ifreeint: scope.row.freeimgnum,
          vfreeint: scope.row.freevidnum
        }
      },
      handleUpdate: async() => {
        await WxusersNumberChange({ ...state.user }).then((res: any) => {
          if (res.code === 0) {
            ElMessage({
              type: 'success',
              message: res?.msg
            })
            state.getAllUser()
            state.dialogVisible = false
          } else {
            ElMessage({
              type: 'error',
              message: res?.msg
            })
          }
        })
      }
    })
    onBeforeMount(() => {
      state.getAllUser(state.listQuery.page, state.listQuery.limit)
    })
    return {
      ...toRefs(state)
    }
  }
})
</script>
