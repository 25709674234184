
import { defineComponent, reactive, toRefs, onBeforeMount } from 'vue'
import { WxusersManage, WxusersNumberChange } from '@/apis/aiface'
import { ElMessage } from 'element-plus'
interface User {
     uid: number
     ifreeint: number
     vfreeint: number
}
const defaultUser: User = {
  uid: 0,
  ifreeint: 0,
  vfreeint: 0
}
export default defineComponent({
  setup() {
    const state = reactive({
      dialogVisible: false,
      wechatname: '',
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id'
      },
      user: Object.assign({}, defaultUser),
      total: 0,
      userList: [],
      getAllUser: async(pageNum?: number, pageSize?: number) => {
        if (pageNum) {
          state.listQuery.page = pageNum
        }
        if (pageSize) {
          state.listQuery.limit = pageSize
        }
        await WxusersManage({ pageNum: state.listQuery.page, pageSize: state.listQuery.limit, wechatname: state.wechatname }).then((res: any) => {
          state.userList = res?.data
          state.total = res.totalpages
        })
      },
      timeSerach() {
        state.getAllUser()
      },
      handleCurrentChange(pageNum?: any) {
        state.getAllUser(pageNum)
      },
      handleSizeChange(val: any) {
        state.getAllUser(0, val)
      },
      handleEditRole: (scope: any) => {
        console.log(scope)
        state.dialogVisible = true
        state.user = {
          uid: scope.row.uid,
          ifreeint: scope.row.freeimgnum,
          vfreeint: scope.row.freevidnum
        }
      },
      handleUpdate: async() => {
        await WxusersNumberChange({ ...state.user }).then((res: any) => {
          if (res.code === 0) {
            ElMessage({
              type: 'success',
              message: res?.msg
            })
            state.getAllUser()
            state.dialogVisible = false
          } else {
            ElMessage({
              type: 'error',
              message: res?.msg
            })
          }
        })
      }
    })
    onBeforeMount(() => {
      state.getAllUser(state.listQuery.page, state.listQuery.limit)
    })
    return {
      ...toRefs(state)
    }
  }
})
